/* Importing a custom font. */
@font-face {
  font-family: "CircularStd";
  src: url("../font/CircularStd-Medium.eot");
  src: url("../font/CircularStd-Medium.woff") format("woff"),
    url("../font/CircularStd-Medium.svg") format("svg");
}

body {
  background-color: black;
  max-width: 1920px;
  margin: auto;
}

body,
html {
  font-family: 'CircularStd', sans-serif;
  color: white;
}

button {
  background-color: black;
  color: white;
  border: 2px solid white;
  border-radius: 25px;
}

button:hover {
  background-color: white;
  color: black;
}

.row {
  --bs-gutter-x: "0";
}

.container {
  max-width: 1920px;
}

.socialsFooter {
  display: none;
}

.mintLink {
  color: white;
  text-decoration: none;
}

@media screen and (max-width: 700px) {
  ul {
    text-align: center;
    list-style-position: inside
  }

  body {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .azoriaFooter {
    padding: 10px 0;
  }

  .socials {
    display: none;
  }

  .socialsFooter {
    display: inline-block;
  }

  .section {
    padding: 0 15px;
  }

  .about,
  .container {
    padding-left: 30px;
  }
}

@media screen and (max-width: 600px) {
  html {
    font-size: calc(1vw + 1vh)
  }

  .pbp {
    padding-bottom: 0;
    width: 190px;
  }
}

@media screen and (max-width: 576px) {
  .footer-links {
    display: block;
  }
}

.footer-links {
  display: inline-block;
}

ul .slider .animated {
  transition-duration: 2000ms !important;
}

.about-rows {
  padding: 40px 0;
}

.pbp {
  padding-bottom: 5px;
  width: 190px;
  display: inline-block;
}

.vert-center {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .footer-links {
    display: block;
  }
}